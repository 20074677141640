/* This example requires Tailwind CSS v2.0+ */
import { Fragment, useContext } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { XIcon } from "@heroicons/react/outline";
import { Link } from "react-router-dom";
import React from "react";
import { Context } from "../App";
import { UtilsJson } from "../utils/UtilsJson";
import PostApi from "../Services/PostApi";
import GetApi from "../Services/GetApi";
import DeleteApi from "../Services/DeleteApi";
export default function CheckoutSideBar() {
  const { CheckoutView, atcProduct, customerID } = useContext(Context);
  const [stateCheckoutView, setstateCheckoutView] = CheckoutView;
  const [stateatcProduct, setstateatcProduct] = atcProduct;
  const [statecustomerID] = customerID;
  const updateProduct = (type, id, att_id) => {
    let addCart = {
      customer_id: statecustomerID,
      product_code: id,
      quantitystatus: type,
      attribute_id:att_id
    };

    PostApi("atcUpdate", addCart).then((list) => {
      setstateatcProduct(list.responcePostData.data);
    });
  };

  const removeProduct = (id) => {
    DeleteApi("atc/" + id).then((e) => {
      GetApi("atc/" + statecustomerID).then((e) => {
        setstateatcProduct(e.responceData.data);
      });
    });
  };

  const ProductPrice = (product) => {
   return product.product_info[0].attribute_id.find((o) => o.att_id === product.attribute_id).price;
  }

  const ProductWeight = (product) => {
    return product.product_info[0].attribute_id.find((o) => o.att_id === product.attribute_id).att_value;
   }


  return (
    <Transition.Root show={stateCheckoutView} as={Fragment}>
      <Dialog
        as="div"
        className="fixed z-50 inset-0 overflow-hidden"
        onClose={() => setstateCheckoutView(false)}
      >
        <div className="absolute inset-0 overflow-hidden">
          <Transition.Child
            as={Fragment}
            enter="ease-in-out duration-500"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in-out duration-500"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="absolute inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-y-0 right-0 pl-10 max-w-full flex">
            <Transition.Child
              as={Fragment}
              enter="transform transition ease-in-out duration-500 sm:duration-700"
              enterFrom="translate-x-full"
              enterTo="translate-x-0"
              leave="transform transition ease-in-out duration-500 sm:duration-700"
              leaveFrom="translate-x-0"
              leaveTo="translate-x-full"
            >
              <div className="w-screen max-w-md">
                <div className="h-full flex flex-col bg-white shadow-xl overflow-y-scroll">
                  <div className="flex-1 py-6 overflow-y-auto px-4 sm:px-6">
                    <div className="flex items-start justify-between">
                      <Dialog.Title className="text-lg font-medium text-gray-900">
                        Shopping cart
                      </Dialog.Title>
                      <div className="ml-3 h-7 flex items-center">
                        <button
                          type="button"
                          className="-m-2 p-2 text-gray-800 hover:text-gray-500"
                          onClick={() => setstateCheckoutView(false)}
                        >
                          <span className="sr-only">Close panel</span>
                          <XIcon className="h-6 w-6" aria-hidden="true" />
                        </button>
                      </div>
                    </div>

                    <div className="mt-8">
                      <div className="flow-root">
                        <ul
                          role="list"
                          className="-my-6 divide-y divide-gray-200"
                        >
                          {stateatcProduct.length ? "" : <p className="text-center text-sm m-5 text-slate-400">No product add to the cart !!</p>}
                          {stateatcProduct.map((product) => (
                           
                            <li key={product.id} className="py-6 flex">
                              <div className="flex-shrink-0 w-12 h-12 sm:w-24 sm:h-24 border border-gray-200 rounded-md overflow-hidden">
                                <img
                                  src={
                                    UtilsJson.baseUrl +
                                    "productimg/" +
                                    product.product_info[0].product_img
                                  }
                                  alt={product.product_info[0].product_img}
                                  className="w-full h-full object-center object-cover"
                                />
                              </div>
                              <div className="ml-4 flex-1 flex flex-col">
                                <div>
                                  <div className="flex justify-between text-xs sm:text-base sm:font-medium text-gray-900">
                                    <h3>
                                      <a href={product.href}>
                                        {product.product_info[0].product_name}
                                      </a>
                                    </h3>
                                    <p className="ml-4">
                                      Rs.
                                      {(
                                        Number(product.quantity) *
                                        Number(ProductPrice(product))
                                      ).toFixed(2)}
                                    </p>
                                  </div>
                                  <p className="mt-1 text-xs text-gray-500">
                                    {"Weight : "+ ProductWeight(product)}
                                  </p>
                                </div>
                                <div className="flex-1 flex items-end justify-between text-xs sm:text-sm text-black align-middle mt-1">
                                  <p className="text-gray-500 flex flex-row">
                                    <button
                                      className={
                                        product.quantity === 1
                                          ? "border-2 border-gold-400 rounded-tl-sm rounded-bl-sm px-2 sm:px-3 sm:py-1  bg-gold-400 text-white  pointer-events-none"
                                          : "border-2 border-gold-800 rounded-tl-sm rounded-bl-sm px-2 sm:px-3 sm:py-1 cursor-pointer bg-gold-800 text-white"
                                      }
                                      onClick={() =>
                                        updateProduct(
                                          "Dec",
                                          product.product_code,
                                          product.attribute_id
                                        )
                                      }
                                    >
                                      -
                                    </button>
                                    <input
                                      className="border-2 border-gold-800 w-10 text-center p-1"
                                      readOnly
                                      type="text"
                                      value={product.quantity}
                                    />{" "}
                                    <button
                                      onClick={() =>
                                        updateProduct(
                                          "Inc",
                                          product.product_code,
                                          product.attribute_id
                                        )
                                      }
                                      className="border-2 border-gold-800 rounded-tr-sm rounded-br-sm px-2 m-0 sm:px-3 sm:py-1 cursor-pointer bg-gold-800 text-white"
                                    >
                                      +
                                    </button>
                                  </p>
                                  <div className="flex">
                                    <button
                                      onClick={() => removeProduct(product.id)}
                                      type="button"
                                      className="font-medium text-indigo-600 hover:text-indigo-500"
                                    >
                                      Remove
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </li>
                          ))}
                        </ul>
                      </div>
                    </div>
                  </div>

                  <div className="border-t border-gray-200 py-6 px-4 sm:px-6">
                    <div className="flex justify-between text-base font-medium text-gray-900">
                      <p>Subtotal</p>
                      <p>
                        Rs.
                        {stateatcProduct.reduce(function (a, b) {
                          return (
                            a +
                            Number(ProductPrice(b)) *
                              b["quantity"]
                          );
                        }, 0)}
                        {".00"}
                      </p>
                    </div>
                    <p className="mt-0.5 text-sm text-gray-500">
                      Shipping and taxes calculated at checkout.
                    </p>
                    <div className="mt-6">
                    {stateatcProduct.length ? 
                      (<Link
                        onClick={() => setstateCheckoutView(false)}
                        to="/checkout"
                        
                        className="flex justify-center items-center px-6 py-3 border border-transparent rounded-md shadow-sm text-base font-medium text-white bg-indigo-600 hover:bg-indigo-700"
                      >
                        Checkout
                      </Link>)
:""}
                    </div>
                    <div className="mt-6 flex justify-center text-sm text-center text-gray-500">
                      <p>
                      {stateatcProduct.length ? 'or' :''} {" "}
                        <button
                          type="button"
                          className="text-indigo-600 font-medium hover:text-indigo-500"
                          onClick={() => setstateCheckoutView(false)}
                        >
                          Continue Shopping
                          <span aria-hidden="true"> &rarr;</span>
                        </button>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
