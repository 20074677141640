import React from "react";
import { Link } from "react-router-dom";
import bg1 from "./../img/success.png";
function OrderStatus() {
  return (
    <section className="text-gray-600">
      <div className=" px-5 py-10 mx-auto">
        <div className="flex flex-wrap justify-center -mx-4 -mb-10 text-center">
          <div className="mb-10 px-4">
            <div className="flex rounded-lg overflow-hidden text-center justify-center">
              <img
                alt="Order placed successfully"                
                src={bg1}
              />
            </div>
            {/* <h2 className="title-font text-md font-medium text-gray-900 mt-3 mb-3">
              Order Placed Successfully
            </h2> */}
            <p className="leading-relaxed text-base"></p>
            <Link
              to="/"
              className="flex items-center justify-center mt-5 bg-blue-600 text-sm font-medium  h-10 rounded text-blue-50 hover:bg-blue-700"
            >
              Back to Home
            </Link>
          </div>
        </div>
      </div>
    </section>
  );
}

export default OrderStatus;
