import React, { useEffect, useState, useContext } from "react";
import { Context } from "../App";
import GetApi from "../Services/GetApi";
import { UtilsJson } from "../utils/UtilsJson";
const OrderList = () => {
  const { customerID } = useContext(Context);
  const [orderList, setOrderList] = useState([]);
  const [statecustomerID] = customerID;
  useEffect(() => {
    GetApi("orderCustomer/" + statecustomerID).then((e) => {
      setOrderList(e.responceData.data);
    });
  },[statecustomerID]);

  function alpDate(params) {
    const d = new Date(params);
    const monthNames = [
      "Jan",
      "Feb",
      "May",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];

    return (
      monthNames[d.getMonth()] + " " + d.getDate() + ", " + d.getFullYear()
    );
  }

  function deliveryStatus(params) {
    let status = "";
    switch (params) {
      case "1":
        status = "Order Placed";
        break;
      case "2":
        status = "Out for Delivery";
        break;
      case "3":
        status = "Deliver Sent";
        break;
      case "4":
        status = "Deliver Rejected by Customer";
        break;
      case "5":
        status = "Customer not in Placed";
        break;

      default:
        break;
    }
    return status;
  }

  const ProductPrice = (product) => {
    return product.product_info[0].attribute_id.find(
      (o) => o.att_id === product.attribute_id
    ).price;
  };

  const ProductWeight = (product) => {
    return product.product_info[0].attribute_id.find(
      (o) => o.att_id === product.attribute_id
    ).att_value;
  };
  
  return (
    <div className="bg-slate-200">
      <div className="py-[64px] px-2">
        {orderList.map((list,i) => (
          <div  key={i} className="mt-1 max-w-2xl px-8 py-4 mx-auto bg-white rounded-lg shadow-md">
            <div className="flex w-full items-center justify-between border-b-2 pb-2">
              <span className="text-xs sm:text-sm  font-light text-gray-800">
                {alpDate(list.created_at)}
              </span>
              <span className="text-xs sm:text-sm font-bold text-gray-800">
                {list.order_id}
              </span>
              <div className="px-3 py-1 text-xs sm:text-sm  font-bold text-white transition-colors duration-200 transform bg-yellow-900 rounded cursor-pointer hover:bg-gray-500">
                {deliveryStatus(list.delivery_status)}
              </div>
            </div>
            {JSON.parse(list.product_id).map((l,inx) => (
              <div key={inx}>
                <div className="flex mt-2 mb-2">
                  <div className="w-3/12">
                    <div className="flex items-center pr-2">
                      <img
                        className="w-full object-cover rounded-lg sm:block"
                        src={
                          UtilsJson.baseUrl +
                          "productimg/" +
                          l.product_info[0].product_img
                        }
                        alt="avatar"
                      />
                    </div>
                  </div>
                  <div className="w-9/12">
                    <div className="mt-0.5">
                      <div className="text-sm cursor-pointer font-bold text-gray-900  hover:text-gray-600 dark:hover:text-gray-200">
                        {l.product_info[0].product_name}
                      </div>
                      <p className="flex justify-between jusmt-2 text-sm text-gray-600">
                        Weight : {ProductWeight(l)}
                      </p>
                      <p className="flex justify-between jusmt-2 text-sm text-gray-600">
                        <span>Quantity : {l.quantity}</span>
                        <span>Rs.{ProductPrice(l)}.00</span>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            ))}
            <div className="text-sm font-light text-gray-800">
              <br />
              {JSON.parse(list.delivery_address).customer_addr_name}
              {",    "}
              <span className="bg-gray-900 text-white p-0.5  px-2 rounded-sm my-3">
                {JSON.parse(list.delivery_address).customer_addr_type}
              </span>
              <br />
              {JSON.parse(list.delivery_address).customer_addr_address},
              {JSON.parse(list.delivery_address).customer_addr_city},
              {JSON.parse(list.delivery_address).customer_addr_state}-{" "}
              {JSON.parse(list.delivery_address).customer_addr_pincode}.
              <br />
              {JSON.parse(list.delivery_address).customer_addr_email}
              <br />
              {JSON.parse(list.delivery_address).customer_addr_phone}
            </div>
            <div className="flex w-full justify-between border-t-2 pb-2 pt-2 mt-2">
              <div className="px-3 py-1 text-sm font-bold text-white transition-colors duration-200 transform bg-green-600 rounded cursor-pointer hover:bg-gray-500">
                {list.payment_mode}
              </div>
              <span className="text-sm font-bold text-gray-800">
                Rs.{list.order_amount}
              </span>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default OrderList;
