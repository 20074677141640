import React from "react";
import QuickViewPopUp from "./QuickViewPopUp";
import Footer from "./Footer";
import ProductList from "./ProductList";
import SlidersImage from "./SlidersImage";
import CategoryList from "./CategoryList";
import AboutUsMin from "./AboutUsMin";
import { useState } from "react";
import HomePopper from "./HomePopper";
import "react-toastify/dist/ReactToastify.css";
function Home() {
  const [homePopUp, sethomePopUp] = useState(true);
  return (
    <div>      
      <SlidersImage />
      <AboutUsMin/>
      <CategoryList />
      <ProductList />
      <QuickViewPopUp />
      <HomePopper homePopUp={homePopUp} sethomePopUp={sethomePopUp} />
      <Footer />      
    </div>
  );
}

export default Home;
