import React, { useState } from "react";
import { toast } from "react-toastify";
import PutApi from "../Services/PutApi";

export default function Profile() {
  const [formErrors] = useState({});
  const [user, setUser] = useState(JSON.parse(localStorage.getItem('customerDetails')));

  const handlechange = (e) => {
    const { name, value } = e.target;
    setUser({ ...user, [name]: value });
  }

  const updateProfile = () => {
    PutApi('customer/' + user.id, user).then((res) => {
      localStorage.setItem('customerDetails', JSON.stringify(res.responcePostData.data[0]))
      toast('Updated Successfully', 'success');
    })
  }

  return (
    <div className="w-full py-[64px] sm:pt-[70px] flex justify-center bg-gray-300  ">
      <div className="bg-white mx-5 shadow overflow-hidden sm:rounded-lg w-full md:w-1/2 h-auto mt-2">
        <div className="px-4 py-5 sm:px-6">
          <h3 className="text-lg leading-6 font-medium text-gray-900">
            Profile
          </h3>
          <p className="mt-1 max-w-2xl text-sm text-gray-500">
            Personal details
          </p>
        </div>
        <div className="border-t border-gray-200">
          <dl>
            <div className="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
              <dt className="text-sm font-medium text-gray-800">First name <span className="text-red-900">*</span></dt>
              <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                <input type="text" className="w-full border border-gray-400 bg-white p-1 fields"
                  name="customer_fname" value={user?.customer_fname} onChange={handlechange} required />
              </dd>
            </div>
            <div className="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
              <dt className="text-sm font-medium text-gray-800">Last name <span className="text-red-900">*</span></dt>
              <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                <input type="text" className="w-full border bg-white border-gray-400 p-1 fields"
                  name="customer_lname" value={user?.customer_lname} onChange={handlechange} required />
                <small
                  id="emailHelp"
                  className="block mt-1 text-xs text-red-600"
                >
                  {formErrors?.customer_lname}
                </small>
              </dd>
            </div>
            <div className="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
              <dt className="text-sm font-medium text-gray-800">
                Phone Number <span className="text-red-900">*</span>
              </dt>
              <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                <input type="number" className=" w-full border bg-white border-gray-400 p-1 fields" name="customer_phone" value={user?.customer_phone} onChange={handlechange} required />
              </dd>
            </div>
            <div className="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
              <dt className="text-sm font-medium text-gray-800">
                Email address <span className="text-red-900">*</span>
              </dt>
              <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                <input type="email" className="w-full border bg-white border-gray-400 p-1 fields" name="customer_email" value={user?.customer_email} onChange={handlechange} required />
              </dd>
            </div>
          </dl>
        </div>
        <div className="px-4 py-5 sm:mb-2 sm:px-6 text-right">
          <button onClick={() => updateProfile()} className="border-2 border-gold-800 rounded-sm rounded-bl-sm px-2 sm:px-3 sm:py-1  bg-gold-800 hover:bg-gold-900 text-white">Save</button>
        </div>
      </div>
    </div>
  );
}
