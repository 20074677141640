import React, { useEffect, useState } from "react";
import { Slide } from "react-slideshow-image";
import "react-slideshow-image/dist/styles.css";
import GetApi from "../Services/GetApi";
import { UtilsJson } from "../utils/UtilsJson";

export default function SlidersImage() {
  const [slideImages, setslideImages] = useState([]);
  useEffect(() => {
    GetApi("banner").then((banner) => {
      setslideImages(banner.responceData.data);
    });
  }, []);

  return (
    <div className="pt-[54px] sm:pt-[64px] bg-slate-200">
      <Slide>
        {!slideImages.length ? (
          <div className="placeholder-content m-2 h-32 sm:h-96">
            <div className="placeholder-content_item"></div>
          </div>
        ) : (
          slideImages.map((slideImage, index) => (
            
            <div className="each-slide  m-2 overflow-hidden" key={index}>
              <img
                src={
                  UtilsJson.baseUrl + "productimg/" + slideImage.banner_image
                }
                className="w-screen rounded-2xl xs:h-48"
                alt=""
              />
            </div>
          ))
        )}
      </Slide>
    </div>
  );
}
