import React, { useContext, Fragment, useState, useEffect } from "react";
import { UtilsJson } from "../utils/UtilsJson";
import { Context } from "../App";
import { Listbox, Transition } from "@headlessui/react";
import { CheckIcon, SelectorIcon } from "@heroicons/react/solid";
import GetApi from "../Services/GetApi";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

function FullProductList(params) {
  const {
    QuickProductView,
    QuickProductViewData
  } = useContext(Context);
  const [, setstateQuickProductView] = QuickProductView;
  const [, setstateQuickProductViewData] = QuickProductViewData;
  const [category, setcategory] = useState([]);
  const [selected, setSelected] = useState(category);
  const { id } = useParams();
  useEffect(() => {
    GetApi("category").then((e) => {
      setcategory(e.responceData.data);
      let passId = id !== undefined ? id : "";
      setSelected(filterObject(category, passId));
    });
  }, []);
  function filterObject(category, id) {
    if (id) {
      let obj = category.find((o) => o.category_no === id);
      return obj;
    } else return [];
  }

  return (
    <section className=" ">
      {/* Same as */}
      <div className="container mx-auto sm:px-2">
        <div className="mb-2 sm:my-2 w-full flex">
          <div className="w-full hidden sm:w-1/4 float-right">
            <Listbox value={selected} onChange={setSelected}>
              {({ open }) => (
                <>
                  <Listbox.Label className="block text-xs font-medium text-gray-700">
                    Filter
                  </Listbox.Label>
                  <div className="mt-1 relative">
                    <Listbox.Button className="relative w-full bg-white border border-gray-300 rounded-md shadow-sm pl-3 pr-10 py-2 text-left cursor-default focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm">
                      {typeof selected !== "undefined" ? (
                        <span className="flex items-center">
                          {selected.category_image ? (
                            <>
                              {" "}
                              <img
                                src={
                                  UtilsJson.baseUrl +
                                  "productimg/" +
                                  selected.category_image
                                }
                                alt=""
                                className="flex-shrink-0 h-6 w-6 rounded-full"
                              />
                              <span className="ml-3  block truncate text-gray-700">
                                {selected.category_name}
                              </span>
                            </>
                          ) : (
                            <span className="flex items-center">
                              <span className="ml-3 block truncate text-gray-700">
                                {" "}
                                Select from the list{" "}
                              </span>
                            </span>
                          )}
                        </span>
                      ) : (
                        <span className="flex items-center">
                          <span className="ml-3 block truncate text-gray-700">
                            {" "}
                            Select from the list{" "}
                          </span>
                        </span>
                      )}
                      <span className="ml-3 absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                        <SelectorIcon
                          className="h-5 w-5 text-gray-800"
                          aria-hidden="true"
                        />
                      </span>
                    </Listbox.Button>
                    <Transition
                      show={open}
                      as={Fragment}
                      leave="transition ease-in duration-100"
                      leaveFrom="opacity-100"
                      leaveTo="opacity-0"
                    >
                      <Listbox.Options
                        // onChange={setselectedProduct()}
                        className="absolute z-10 mt-1 w-full bg-white shadow-lg max-h-56 rounded-md py-1 text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm"
                      >
                        {category.map((person) => (
                          <Listbox.Option
                            key={person.category_no}
                            className={({ active }) =>
                              classNames(
                                active
                                  ? "text-white bg-indigo-600"
                                  : "text-gray-900",
                                "cursor-default select-none relative py-2 pl-3 pr-9"
                              )
                            }
                            value={person}
                          >
                            {({ selected, active }) => (
                              <>
                                <div className="flex items-center">
                                  <img
                                    src={
                                      UtilsJson.baseUrl +
                                      "productimg/" +
                                      person.category_image
                                    }
                                    alt=""
                                    className="flex-shrink-0 h-6 w-6 rounded-full"
                                  />
                                  <span
                                    className={classNames(
                                      selected
                                        ? "font-semibold"
                                        : "font-normal",
                                      "ml-3 block truncate"
                                    )}
                                  >
                                    {person.category_name}
                                  </span>
                                </div>

                                {selected ? (
                                  <span
                                    className={classNames(
                                      active ? "text-white" : "text-indigo-600",
                                      "absolute inset-y-0 right-0 flex items-center pr-4"
                                    )}
                                  >
                                    <CheckIcon
                                      className="h-5 w-5"
                                      aria-hidden="true"
                                    />
                                  </span>
                                ) : null}
                              </>
                            )}
                          </Listbox.Option>
                        ))}
                      </Listbox.Options>
                    </Transition>
                  </div>
                </>
              )}
            </Listbox>
          </div>
        </div>
        <div className="grid grid-cols-2 gap-y-4 gap-x-2 md:grid-cols-1 lg:grid-cols-4 xl:grid-cols-4 xl:gap-x-4">
          {params.stateFilterProductList.map((e) =>
            e.visible === "V" ? (
              <div
                key={Math.random()}
                className="flex flex-col justify-between max-w-md mx-auto overflow-hidden bg-white rounded-lg cursor-pointer shadow-md hover:shadow-2xl "
              >
                <div className="w-12/12 shadow-lg">
                  <img
                    onClick={() => {
                      setstateQuickProductView(true);
                      setstateQuickProductViewData(e);
                    }}
                    src={UtilsJson.baseUrl + "productimg/" + e.product_img}
                    className="w-full h-full bg-cover object-cover object-center"
                    alt=""
                  />
                </div>
                <div className="w-12/12 p-2 sm:p-2 flex justify-center sm:justify-between flex-col">
                  <h1 className="text-md md:text-xl text-center font-bold text-gold-800 font-dancing">
                    {e.product_name}
                  </h1>
                  {/* <p className="text-xs sm:text-sm text-gray-600 text-center">
                    {e.product_description}
                  </p> */}
                  <div className="flex justify-center mt-1 item-center flex-col items-baseline">
                    <h1 className="text-xs font-normal text-blue-900 text-center w-full  md:text-lg">
                    Rs {Number(e?.attribute_id[0]?.price ||e?.attribute_id[1]?.price ||e?.attribute_id[2]?.price || 0).toFixed(2)}
                    </h1>
                    <button
                       onClick={() => {
                        setstateQuickProductView(true);
                        setstateQuickProductViewData(e);
                      }}
                      className="px-1 py-2 sm:px-2 mt-1 text-xs w-full text-white uppercase transition-colors duration-200 transform bg-gold-800 rounded  hover:bg-gold-700 dark:hover:bg-gray-600 focus:outline-none focus:bg-gray-700 dark:focus:bg-gray-600"
                    >
                      Add to Cart
                    </button>
                    {/* {!checkAtcBagState(stateatcProduct, e.product_code) ? (
                      <button
                        onClick={() => setToCart(e)}
                        className="px-1 py-2 sm:px-2 mt-1 text-xs w-full font-bold text-white uppercase transition-colors duration-200 transform bg-gold-800 rounded  hover:bg-gold-700 dark:hover:bg-gray-600 focus:outline-none focus:bg-gray-700 dark:focus:bg-gray-600"
                      >
                        Add to Cart
                      </button>
                    ) : (
                      <p className="text-gray-500 flex flex-row w-full mt-2 justify-center ">
                        <button
                          className={
                            "border-2 border-gold-800 rounded-tl-sm rounded-bl-sm px-3 sm:px-3 sm:py-1 cursor-pointer bg-gold-800 text-white"
                          }
                          onClick={() =>
                            updateProduct(
                              "Dec",
                              e.product_code,
                              checkProduct(stateatcProduct, e.product_code)
                                .quantity,
                              checkProduct(stateatcProduct, e.product_code).id
                            )
                          }
                        >
                          -
                        </button>
                        <input
                          className="border-2 border-gold-800 w-10 text-center p-1 text-black"
                          readOnly
                          type="text"
                          value={
                            checkProduct(stateatcProduct, e.product_code)
                              .quantity
                          }
                        />{" "}
                        <button
                          onClick={() =>
                            updateProduct(
                              "Inc",
                              e.product_code,
                              checkProduct(stateatcProduct, e.product_code)
                                .quantity,
                              checkProduct(stateatcProduct, e.product_code).id
                            )
                          }
                          className="border-2 border-gold-800 rounded-tr-sm rounded-br-sm px-3 m-0 sm:px-3 sm:py-1 cursor-pointer bg-gold-800 text-white"
                        >
                          +
                        </button>
                      </p>
                    )} */}
                  </div>
                </div>
              </div>
            ) : (
              ""
            )
          )}
        </div>
      </div>
    </section>
  );
}

export default FullProductList;
