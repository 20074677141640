import "./App.css";
import Home from "./Components/Home";
import Header from "./Components/Header";
import Shop from "./Components/Shop";
import CheckoutSideBar from "./Components/CheckoutSideBar";
import React, { useState, createContext, useEffect } from "react";
import { HashRouter as Router, Routes, Route } from "react-router-dom";
import FullCheckout from "./Components/FullCheckout";
import OrderStatus from "./Components/OrderStatus";
import ScrollToTop from "./Components/scrollToTop";
import GetApi from "./Services/GetApi";
import ContactUs from "./Components/ContactUs";
import OrderList from "./Components/OrderList";
import AboutUsMin from "./Components/AboutUsMin";
import Profile from "./Components/Profile";
import PrivacyPolicy from "./Components/PrivacyPolicy";
import TermsAndCondition from "./Components/TermsAndCondition";
import CancellationPolicy from "./Components/CancellationPolicy";
import ShippingDelivery from "./Components/ShippingDelivery";

export const Context = createContext();

function App() {
  const [ProductList, setProductList] = useState([]);
  const [QuickProductView, setQuickProductView] = useState(false);
  const [QuickProductViewData, setQuickProductViewData] = useState([]);
  const [CheckoutView, setCheckoutView] = useState(false);
  const [atcProduct, setatcProduct] = useState([]);
  const [FilterProductList, setFilterProductList]= useState([]);
  const [customerID, setcustomerID] = useState(
    localStorage.getItem("customer") || null
  );
  const [loginPopUpView, setloginPopUpView] = useState(false);
  useEffect(() => {
    GetApi("product").then((e) => {
      setProductList(e.responceData.data);
    });
  }, []);

  useEffect(() => {
    if (customerID !== null) {
      GetApi("atc/" + customerID).then((e) => {
        setatcProduct(e.responceData.data);
      });
    }
  }, []);

  return (
    <Router>
      <Context.Provider
        value={{
          ProductList: [ProductList, setProductList],
          FilterProductList : [FilterProductList , setFilterProductList],
          QuickProductView: [QuickProductView, setQuickProductView],
          QuickProductViewData: [QuickProductViewData, setQuickProductViewData],
          CheckoutView: [CheckoutView, setCheckoutView],
          atcProduct: [atcProduct, setatcProduct],
          customerID: [customerID, setcustomerID],
          loginPopUpView: [loginPopUpView, setloginPopUpView],
        }}
      >
        <ScrollToTop />
        <Header />
        <CheckoutSideBar />
        <Routes basename="/ ">
          <Route path="/" exact element={<Home />} />
          <Route path="/shop/:id" element={<Shop />} />
          <Route path="/shop" element={<Shop />} />
          <Route path="/checkout" element={<FullCheckout />} />
          <Route path="/about-us" element={<AboutUsMin />} />
          <Route path="/order-status" element={<OrderStatus />} />
          <Route path="/contact-us" element={<ContactUs />} />
          <Route path="/order-list" element={<OrderList />} />
          <Route path="/profile" element={<Profile />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route path="/terms-and-condition" element={<TermsAndCondition />} />
          <Route path="/refund-and-cancellation-policy" element={<CancellationPolicy />} />
          <Route path="/shipping-delivery" element={<ShippingDelivery />} />
          <Route path="*" exact element={<Home />} />
        </Routes>
      </Context.Provider>
    </Router>
  );
}

export default App;
