import axios from "axios";
import { UtilsJson } from "../utils/UtilsJson";

async function DeleteApi(url) {
  let responceDeleteData = '';
  let loadingDeleteData= '';
  let errorDeleteData = '';
  
  await axios
    .delete(UtilsJson.baseUrl + url)
    .then((response) => {
      responceDeleteData = response;
    })
    .catch((err) => {
      errorDeleteData = err;
    })
    .finally(() => {
      loadingDeleteData = false;
    });

  return { responceDeleteData, loadingDeleteData, errorDeleteData };
}

export default DeleteApi;
