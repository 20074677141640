import React , { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import bg1 from "./../img/SVS-1.png";
import bg2 from "./../img/bg-2.jpg";
import bg3 from "./../img/bg-1.jpg";
import GetApi from "../Services/GetApi";
import Footer from "./Footer";

function AboutUsMin() {
  const location = useLocation();
  const [details, setDetails] = useState([]);
  useEffect(() => {
    GetApi("settings").then((e) => {
      setDetails(e.responceData.data[0])
    })
  },[]);
  return (
    <>
    <div  className={
      location.pathname === "/" || !location.pathname || typeof(location.pathname) == 'undefined'
        ? "px-4 md:py-16 py-5 mx-auto md:px-24 lg:px-8 lg:py-20 bg-slate-200 hidden sm:block"
        : "sm:py-16 mx-auto md:px-24 lg:px-8 lg:py-20 py-[64px] px-5 bg-slate-200"
    }
   >
      <div className="grid gap-10 lg:grid-cols-2">
        <div className="flex flex-col items-center md: text-center justify-center md:pr-8 xl:pr-0 lg:max-w-lg">
          <div className="flex items-center justify-center w-16 h-16 sm:w-24 sm:h-24 mb-4 rounded-full bg-violet-900">
            <span className="inline-block font-extrabold text-gold-900 text-3xl sm:text-5xl font-dancing ">
              75<sup>th</sup>
            </span>
          </div>
          <div className="max-w-xl mb-6">
            <h2 className="max-w-lg mb-3 sm:mb-6 font-sans text-xl font-bold tracking-tight text-gray-900 sm:text-4xl sm:leading-none">
              Year in the Sweet <br className="hidden md:block" />
              Industry
            </h2>
            <p className="text-xs text-gray-700 md:text-sm text-justify">
             {details.aboutus ? details.aboutus : 
              <div>
                <div className="placeholder-content placeholder-content-blue m-1 h-5 border-r-2"></div>
                <div className="placeholder-content placeholder-content-blue m-1 h-5 border-r-2"></div>
                <div className="placeholder-content placeholder-content-blue m-1 h-5 border-r-2"></div>
                <div className="placeholder-content placeholder-content-blue m-1 h-5 border-r-2"></div>
                <div className="placeholder-content placeholder-content-blue m-1 h-5 border-r-2"></div>
             </div> 
             }
            </p>
          </div>
          <div>
            {/* <NavLink
              to="/about-us"
              aria-label=""
              className="flex align-baseline  text-xs items-center font-semibold transition-colors duration-200 text-violet-900 hover:text-violet-800"
            >
              <span>Learn more</span> 
              <svg
                className="inline-block w-2 ml-2 text-xs"
                fill="currentColor"
                viewBox="0 0 12 12"
              >
                <path d="M9.707,5.293l-5-5A1,1,0,0,0,3.293,1.707L7.586,6,3.293,10.293a1,1,0,1,0,1.414,1.414l5-5A1,1,0,0,0,9.707,5.293Z" />
              </svg>
            </NavLink> */}
          </div>
        </div>
        <div className="flex items-center justify-center -mx-4 lg:pl-8">
          <div className="flex flex-col items-end px-3">
            <img
              className="object-cover mb-6 rounded shadow-lg h-28 sm:h-48 xl:h-56 w-28 sm:w-48 xl:w-56"
              src={bg3}
              alt=""
            />
            <img
              className="object-cover w-20 h-20 rounded shadow-lg sm:h-32 xl:h-40 sm:w-32 xl:w-40"
              src={bg2}
              alt=""
            />
          </div>
          <div className="px-3">
            <img
              className="object-cover w-40 h-40 rounded shadow-lg sm:h-64 xl:h-80 sm:w-64 xl:w-80"
              src={bg1}
              alt=""
            />
          </div>
        </div>
      </div>
    </div>
    {location.pathname !== "/" ? <Footer></Footer> : ""}
    </>
  );
}

export default AboutUsMin;
