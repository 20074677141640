import Footer from "./Footer";

function PrivacyPolicy() {
  return (
    <>
      <div className="sm:py-16 mx-auto md:px-24 lg:px-8 lg:py-20 py-[64px] px-5 bg-slate-200 text-gray-700">
        <h1 className="text-5xl font-extrabold text-center py-5 font-dancing text-gold-900">Privacy Policy</h1>
        <p className="text-justify">
          <strong>Collection of Information</strong>
        </p>
        <p className="text-justify">
          If you browse through this site without providing us with any personal
          information, we will gather and store some information about your
          visit, such as IP address, type of browser and operating system used,
          date and time you access our site, pages you visit, and if you linked
          to our website from another website, the address of that website. This
          information will not identify you personally and will not be linked
          back to you.
        </p>
        <p className="text-justify">
          There will be times, such as when you submit a registration or fill
          out our contact form we will need to obtain personally identifiable
          information from you or about you. Such personally identifiable
          information may include your Email, address, telephone number and
          identification number.
        </p>
        <p className="text-justify m-3"></p>
        <p className="text-justify">
          <strong>Use and Sharing of Information</strong>
        </p>
        <p className="text-justify">
          We do not share, sell, trade or rent your personal information to
          third parties for unknown reasons.
        </p>
        <p className="text-justify">
          At no time will we sell your personally-identifiable data without your
          permission unless set forth in this Privacy Policy. The information we
          receive about you or from you may be used by us or shared by us with
          our corporate affiliates, dealers, agents, vendors and other third
          parties to help process your request; to comply with any law,
          regulation, audit or court order; to help improve our website or the
          products or services we offer; for research; to better understand our
          customers' needs; to develop new offerings; and to alert you to new
          products and services (of us or our business associates) in which you
          may be interested. We may also combine information you provide us with
          information about you that is available to us internally or from other
          sources in order to better serve you.
        </p>
        <p className="text-justify m-3"></p>
        <p className="text-justify">
          <strong>Cookies</strong>
        </p>
        <p className="text-justify">
          From time to time, we may place "cookies" on your personal computer.
          "Cookies" are small identifiers sent from a web server and stored on
          your computer's hard drive, that help us to recognize you if you visit
          our website again. Also, our site uses cookies to track how you found
          our site. To protect your privacy we do not use cookies to store or
          transmit any personal information about you on the Internet. You have
          the ability to accept or decline cookies. Most web browsers
          automatically accept cookies, but you can usually modify your browser
          setting to decline cookies if you prefer. If you choose to decline
          cookies certain features of the site may not function property or at
          all as a result.
        </p>
        <p className="text-justify m-3"></p>
        <p className="text-justify">
          <strong>Links</strong>
        </p>
        <p className="text-justify">
          Our website contains links to other sites. Such other sites may use
          information about your visit to this website. Our Privacy Policy does
          not apply to practices of such sites that we do not own or control or
          to people we do not employ. Therefore, we are not responsible for the
          privacy practices or the accuracy or the integrity of the content
          included on such other sites. We encourage you to read the individual
          privacy statements of such websites.
        </p>
        <p className="text-justify m-3"></p>
        <p className="text-justify">
          <strong>Security</strong>
        </p>
        <p className="text-justify">
          We safeguard your privacy using known security standards and
          procedures and comply with applicable privacy laws. Our websites
          combine industry-approved physical, electronic and procedural
          safeguards to ensure that your information is well protected though
          it's life cycle in our infrastructure.
        </p>
        <p className="text-justify">
          Sensitive data is hashed or encrypted when it is stored in our
          infrastructure. Sensitive data is decrypted, processed and immediately
          re-encrypted or discarded when no longer necessary. We host web
          services in audited data centers, with restricted access to the data
          processing servers. Controlled access, 24/7 staffed security in such
          data centers ensure that we provide secure hosting.
        </p>
        <p className="text-justify m-3"></p>
        <p className="text-justify">
          <strong>Newsletter</strong>
        </p>
        <p className="text-justify">
          By providing email address to OYC at any point of time, you consent to
          our terms that we are eligible to send you emails with promotional
          contents, emails for any notification that we may deem fit. And at any
          time, if you do not want any of the email from us to reach you, please
          unsubscribe from here or email us from our contact page.
        </p>
        <p className="text-justify m-3"></p>
        <p className="text-justify">
          <strong>Opt-Out Policy</strong>
        </p>
        <p className="text-justify">
          Please email<span class="apple-converted-space"> </span>
          <a href="mailto:info@srivenkateshwaraclassic.com">
            info@srivenkateshwaraclassic.com
          </a>
          <span class="apple-converted-space"> </span>if you no longer wish to
          receive any information from us.
        </p>
        <p className="text-justify m-3"></p>
        <p className="text-justify">
          <strong>Changes to this Privacy Policy</strong>
        </p>
        <p className="text-justify">
          Our privacy policy was last updated on July 01, 2022. We may change
          our Privacy Policy from time to time. If we do, we will update this
          Privacy Policy on our website.
        </p>
      </div>
      <Footer></Footer>
    </>
  );
}

export default PrivacyPolicy;
